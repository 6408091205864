@import "../../scss/vars.scss";

.section-hero {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: $primary;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .headline {
    width: 100%;
    z-index: 1;
    position: absolute;
    bottom: 40%;
    text-align: left;
    color: $white;
  }

  .scroll-arrow {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    .arrow {
      font-size: 2rem;
      color: $white;
      animation: bounce 1s infinite;
    }
  }
}

/* Animation für das Bouncing */
@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }
}