@import "../../scss/vars.scss";

.section-skills {

  .headline,
  .copy {
    text-align: center;
  }

  .headline {
    grid-column: 1/13;
  }

  .content {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    .right-skill-wrapper,
    .left-skill-wrapper {
    padding: 50px 10px 35px 10px;
    
      @media screen and (min-width:768px) {
        padding: 60px 0 45px;
      }

      .headline {
        margin-bottom: 30px;
      }
    }

    .right-skill-wrapper {
      grid-column: 1/13;
      border: 3px solid $primary;
      border-top: none;

      @media screen and (min-width:768px) {
        grid-column: 7/13;
        border-left: none;
        border-top: 3px solid $primary;
      }

      .headline {
        color: $primary;
      }

      .skilltag {
        text-align: center;
        width: 200px;
      }
    }

    .left-skill-wrapper {
      grid-column: 1/13;
      border: 3px solid $black;
      border-bottom: none;

      @media screen and (min-width:768px) {
        grid-column: 1/7;
        border-right: none;
        border-bottom: 3px solid $black;
      }
    }

    .leftskills {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 300px;
      margin: auto;
    }

    .rightskills {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      max-width: 300px;
      margin: auto;
      align-items: center;

      .skilltag {
        background-color: $black;
        color: $white;
      }
    }

    .skilltag {
      text-transform: none;
      margin: 5px 5px 0 0;
      padding: 4px 8px;
      border-radius: 2px;
      background-color: $primary;
      color: $white;
    }
  }

  .productblock {
    background-color: $bg-gray;
    grid-column: 1/13;
    padding: 30px 50px 20px 50px;
    margin-top: 30px;
  }

  .productblock-inner {
    padding: 15px px;
    background-color: $bg-white;
    margin: auto;
  }
}