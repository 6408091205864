@import "../../scss/vars.scss";

.section-projects {
  background-color: $bg-white;

  .swiper-wrapper {
    margin-bottom: 30px;

    @media screen and (min-width: $lg) {
      margin-bottom: 60px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    bottom: 0;
    top: unset;

    &::after {
      font-size: 24px;
    }
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, 50px);
    right: auto;
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, 50px);
    left: auto;
  }

  .swiper {
    grid-column: 1 / 13;
    width: 100%;
  }

  .headline {
    grid-column: 2 / 12;
  }

  .text-image {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $lg) {
      flex-direction: row;
    }

    picture,
    .text {
      display: flex;
      align-items: center;
    }

    picture {
      width: 100%;

      @media screen and (min-width: $lg) {
        width: 50%;
      }

      @media screen and (min-width: $xl) {
        width: 70%;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .text {
      width: 100%;

      @media screen and (min-width: $lg) {
        width: 50%;
      }

      @media screen and (min-width: $xl) {
        width: 30%;
      }

      ul {
        width: 100%;
        margin: 30px 15px 15px;
        padding: 0 15px;

        @media screen and (min-width: $lg) {
          padding: 0 30px;
        }
      }

      li {
        list-style: none;
        margin-bottom: 10px;

        b {
          color: $gray;
        }
      }

      .techstack-wrapper {
        display: flex;
        flex-wrap: wrap;
      }

      .skilltag {
        font-size: 0.8rem;
        text-transform: none;
        margin: 5px 5px 0 0;
        padding: 4px 8px;
        border-radius: 2px;
        background-color: $primary;
        color: $white;
      }
    }
  }
}