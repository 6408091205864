.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.cols {
  grid-column: 2 / 12; 
  z-index: 1;

  @media screen and (min-width: $md) {
    grid-column: 2 / 12; 
  }

  @media screen and (min-width: $lg) {
    grid-column: 2 / 12;
  }

  @media screen and (min-width: $xxl) {
    grid-column: 4 / 10;
  }
}

.comp-spacing {
  padding: 100px 0 70px 0;

  @media screen and (min-width:992px) {
    padding: 150px 0 120px 0;
  }
}

.headline {
  text-align: center;
  margin-bottom: 60px;
}