@import "../../scss/vars.scss";

.icon-link {
  align-items: baseline;
  white-space: nowrap;
  text-underline-offset: 3px;
  text-decoration: underline;
  color: $primary;
  transition: color 0.3s ease;

  &:hover {
    color: $primary-hover;

    span {
      color: $primary-hover;
      transition: color 0.3s ease;
    }
  }

  i {
    margin-right: 10px;
  }

  &.external {
    i {
      margin-left: 10px;
    }
  }
}