@import "../../scss/vars.scss";

.main-nav {
  position: absolute;
  width: 100%;
  z-index: 1;
  padding: 20px 0;

  @media screen and (min-width: 992px) {
    padding: 20px 0;
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
  }

  button {
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    color: $white;
    padding: 10px;
    font-weight: bold;
    justify-content: center;
    background-color: transparent;
    transition: background-color 0.3s ease;

    &.active {
      background-color: $white;
      color: $black;
    }
  }

  span {
    color: $black;
    margin: 0 10px;
  }
}