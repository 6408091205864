@import "../src/scss/vars.scss";
@import "../src/scss/fonts.scss";
@import "../src/scss/layout.scss";
@import "../src/assets/fontawesome-free-6.6.0-web/css/all.min.css";

body {
  margin: 0 auto;
  color: $black;
  background-color: $bg-white;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: $white; 
}
 
::-webkit-scrollbar-thumb {
  background: $primary; 
}

::-webkit-scrollbar-thumb:hover {
  background: $primary-hover; 
}