@import "../../scss/vars.scss";

.section-cta-bar {
  position: relative;
  -webkit-box-shadow: 0px -5px 15px -5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px -5px 15px -5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px -5px 15px -5px rgba(0, 0, 0, 0.3);

  .content {
    position: relative;
    background-color: $primary;

    &:hover {
      background-color: $primary-hover;
      transition: background-color 0.3s ease;
    }

    span,
    a {
      color: $white;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 18px;
      width: 100%;
      padding: 30px;

      span {
        font-weight: 600;
      }
    }

    i {
      margin-right: 5px;
    }
  }
}