$black: #333333;
$white: #ffffff;

$primary: #009DFF;
$primary-hover: #0074E0;

$highlight-green: #ADF562;
$highlight-green-hover: #A5E95D;

$highlight-red: #D13400;
$highlight-red-hover: #B52B00;

$gray-light: #9aa5ad;
$gray: #717C86;
$gray-dark: #6E7884;

$bg-dark: #2C3241;
$bg-dark-alt: #1B1E26;
$bg-white: $white;
$bg-gray: #F9F8FB;

$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;