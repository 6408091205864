@import "../../scss/vars.scss";

.section-intro {
  overflow: hidden;

  .content-webdev {
    background-color: $white;
    margin-top: 50px;
    position: relative;
    grid-column: 1/13;

    &:after {
      border-top: 5px solid $primary;
      border-left: 5px solid $primary;
      box-sizing: border-box;
      content: "";
      height: 100%;
      left: -100px;
      position: absolute;
      top: -50px;
      width: 100%;
      z-index: -1;
    }

    .textblock {
      text-align: left;
      grid-column: 1/13;
      margin-bottom: 30px;
      background-color: $white;
      padding: 20px 0 0 0;

      .title {
        color: $gray;
        margin-bottom: 40px;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 50px;
          height: 3px;
          background-color: $primary;
          bottom: -12px;
          left: 0;
        }
      }

      .headline {
        text-align: left;
        margin-bottom: 5px;
      }
    }
  }

  .content-gamedev {
    background-color: $white;
    margin-top: 100px;
    position: relative;
    grid-column: 1/13;

    &:after {
      border-right: 5px solid $black;
      border-bottom: 5px solid $black;
      box-sizing: border-box;
      content: "";
      height: 100%;
      right: -100px;
      position: absolute;
      bottom: -50px;
      width: 100%;
      z-index: -1;
    }

    .textblock {
      text-align: right;
      grid-column: 1/13;
      margin-bottom: 30px;
      background-color: $white;
      padding: 20px 0 0 0;

      .title {
        color: $gray;
        margin-bottom: 40px;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 50px;
          height: 3px;
          background-color: $black;
          bottom: -12px;
          right: 0;
        }
      }

      .headline {
        text-align: right;
        margin-bottom: 5px;
        color: $primary;
      }
    }
  }





}