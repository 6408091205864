@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto-regular.eot');
  /* IE9 Compat Modes */
  src: url('../assets/fonts/roboto-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../assets/fonts/roboto-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../assets/fonts/roboto-regular.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('../assets/fonts/roboto-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../assets/fonts/roboto-regular.svg#svgFontName') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

body {
  font-family: 'Roboto', 'Open Sans', sans-serif;
  text-align: left;
  font-size: 1rem;
  line-height: 1.6;
}

h1,
h2,
h3,
p,
a,
strong,
li,
span,
button {
  margin: 0;
  padding: 0;
  letter-spacing: 0.075rem;
}

h1,
h2,
h3 {
  text-transform: uppercase;
}

h1 {
  font-size: 1.8rem;
  line-height: 1.8rem;

  @media screen and (min-width:1200px) {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
}

h2 {
  font-size: 1.6rem;
  line-height: 1.6rem;

  @media screen and (min-width:1200px) {
    font-size: 2rem;
    line-height: 2rem;
  }
}

.title {
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin-bottom: 30px;
  font-weight: 600;
  text-transform: uppercase;

  @media screen and (min-width:1200px) {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
}

.copyright {
  margin-bottom: 0;
  font-size: 0.8rem;
  line-height: 1.2;
}

.linkstyle {
  background: none;
  border: none;
  font-size: 1rem;
  line-height: 1.6;

  &:hover {
    text-underline-offset: 3px;
    cursor: pointer;
  }
}