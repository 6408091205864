@import "../../scss/vars.scss";

.footer {
  background-color: $bg-dark;

  .footer-wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 160px 0 80px;

    @media screen and (min-width:768px) {
      padding: 200px 0 100px;
    }

    .copyright {
      color: $gray-dark;
    }

    .content {
      grid-column: 1/13;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    button:hover {
      color: $white;
      transition: color 0.3s ease;
    }

    .footer-nav {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .footer-nav {
      margin-bottom: 15px;
    }

    .logo-wrapper {
      display: flex;
      flex-wrap: nowrap;
      margin: 0 0 30px;
    }

    .logo-spacing {
      margin-right: 30px
    }

    .linkstyle {
      color: $gray-light;
    }
  }

  .seperator {
    display: block;
    min-width: 3px;
    max-width: 3px;
    height: 15px;
    background-color: $primary;
    margin: 0 15px;
  }
}