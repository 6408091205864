@import "../../scss/vars.scss";

.section-usp {
  background-color: $bg-gray;
  border-top: 1px solid rgba(179, 186, 190, 0.2);
  border-bottom: 1px solid rgba(179, 186, 190, 0.2);

  .usp-wrapper {
    padding: 50px;
    background-color: $bg-white;
    margin: auto;
    -webkit-box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
  }

  .copy {
    text-align: center;
    margin-bottom: 60px;
  }

  .usp-item-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    justify-content: space-around;
  }

  .item {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    height: auto;
    width: 250px;
    margin-bottom: 60px;

    &:last-child {
        margin-bottom: 0;
      }

    @media screen and (min-width: $md) {
      margin-bottom: 0;
    }

    i {
      font-size: 60px;
      margin-bottom: 30px;
    }
  }
}