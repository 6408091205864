@import "../../scss/vars.scss";

.section-clients {
  background-color: $bg-gray;
  border-top: 1px solid rgba(179, 186, 190, 0.2);
  border-bottom: 1px solid rgba(179, 186, 190, 0.2);

  .content{
        grid-column: 2/12;
  }

  .card-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .card {
    background-color: $bg-white;
    margin: 15px;
    padding: 35px;
    max-width: 250px;
    -webkit-box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
  }

  img {
    aspect-ratio: 4 / 3;
    width: 100%;
    object-fit: contain;
  }

  .card-body {
    text-align: center;
    margin-top: 15px;
    background-color: $bg-gray;
    font-weight: 700;
  }

}