@import "../../scss/vars.scss";

.section-quote {
  background-color: $bg-gray;
  border-top: 1px solid rgba(179, 186, 190, 0.2);
  border-bottom: 1px solid rgba(179, 186, 190, 0.2);




  .headline {
    font-size: 60px;
    color: $primary;
    margin: 0;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 50%;
      height: 3px;
      background-color: $primary;
      top: -45px;
      left: 0;
    }

    &:after {
      content: "";
      position: absolute;
      width: 50%;
      height: 3px;
      background-color: $black;
      bottom: -45px;
      right: 0;
    }
  }

}